<script lang="jsx">
import { organizationNav } from '@/config/nav'
import TojoyInnerLayout from '@/components/layout/inner'

export default {
  name: 'democracy-index-view',

  components: {
    TojoyInnerLayout
  },

  data() {
    return {
      breadcrumbList: [
        {
          title: '首页',
          route: 'Home'
        },
        {
          title: '组织绩效',
          route: 'scoreSum'
        }
      ],
      navList: organizationNav.children
    }
  },

  watch: {
    '$route': {
      immediate: true,
      handler(newVal, oldVal) {
        this.$nextTick(() => {
          if (newVal?.name === 'democracy-inviter') {
            this.breadcrumbList.push({ title: '我的评价人', route: 'democracy-inviter' })
          }
          if (oldVal?.name === 'democracy-inviter') {
            this.breadcrumbList.pop()
          }
        })
      }
    }
  },
  render(h) {
    return (
      <tojoy-inner-layout
        ref="tojoy-inner-layout"
        class="organization-index-view"
        breadcrumb-list={this.breadcrumbList}
        navList={this.navList}
        options={{breadcrumb:true, nav:true}}
      />
    )
  },
}
</script>

<style lang="scss">
.organization-index-view {
  .tojoy-title-content__footer {
    padding: 0 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .el-button--small {
      height: 32px;
    }
  }
}
</style>
