<script lang="jsx">
import { organizationNav } from '@/config/nav'
import TojoyInnerLayout from '@/components/layout/inner'

export default {
  name: 'democracy-index-view',

  components: {
    TojoyInnerLayout
  },

  data() {
    return {
      breadcrumbList: [
        {
          title: '首页',
          route: 'Home'
        },
        {
          title: '创新成果',
          route: 'channel'
        }
      ],
      navList:[]
    }
  },
  mounted() {
    // this.$nextTick(()=>{
    //   this.$refs['tojoy-inner-layout'].navSetActive('democracy-mine')
    // })
    // setTimeout(() => {
    //   this.$refs['tojoy-inner-layout'].breadcrumbSet(0, { title: '这是换个标题' })
    // }, 5000)

    // setTimeout(() => {
    //   this.$refs['tojoy-inner-layout'].breadcrumbPush({ title: '这是新增的标题' })
    // }, 8000)
  },

  render(h) {
    return (
      <tojoy-inner-layout
        ref="tojoy-inner-layout"
        class="democracy-index-view"
        breadcrumb-list={this.breadcrumbList}
        navList={this.navList}
        options={{breadcrumb:true, nav:true}}
      />
    )
  },
}
</script>

<style lang="scss">
.democracy-index-view {
  .tojoy-title-content__footer {
    padding: 0 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .el-button--small {
      height: 32px;
    }
  }
}
</style>
