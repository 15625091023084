<script lang="jsx">
import { organizationNav } from '@/config/nav'
import TojoyInnerLayout from '@/components/layout/inner'

export default {
  name: 'democracy-index-view',

  components: {
    TojoyInnerLayout
  },

  data() {
    return {
      navList: []
    }
  },

  created() {
    const newTitles = {'customer2-channel': sessionStorage.getItem('name'), 'customer-channel': sessionStorage.getItem('name')}
    sessionStorage.setItem('exchange-title', JSON.stringify(newTitles))
  },

  render(h) {
    return (
      <tojoy-inner-layout
        ref="tojoy-inner-layout"
        class="democracy-index-view"
        is-exchange={true}
        options={{breadcrumb:true, nav:false}}
      />
    )
  },
}
</script>

<style lang="scss">
.democracy-index-view {
  .tojoy-title-content__footer {
    padding: 0 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .el-button--small {
      height: 32px;
    }
  }
}
</style>
